a.logo {
  color: rgb(70, 70, 70);
  font-family: 'Roboto Mono', monospace;
  font-weight: 700;
  font-size: 21px;
  text-transform: lowercase;
  line-height: 29px;
}

a.logo:hover {
  color: #00A9A5;
  text-decoration: none;
}

.logoTextSemicolon {
  font-size: 22px;
  margin-left: 1px;
  color: #00A9A5;
}
