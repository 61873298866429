@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,500;1,700;1,900&display=swap');

 @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@700&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', Arial, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  color: #232528;
  -moz-text-size-adjust: none;
}

a,
a:hover {
  color:#00A9A5;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

html {
  min-height: 100%;
  display: flex;
}

body, div#__next {
  max-width: 100%;
  display: flex;
  flex: 1;
}

div#__next > div.container,
div#__next > div.container-fluid {
  display: flex;
  flex-direction: column;
}
