.footer {
  flex: 0 !important;
  background-color: rgb(249, 249, 249);
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-size: 11px;
  line-height: 40px;
  font-family: 'Montserrat', sans-serif;
}

.footer,
.footer a {
  color: rgb(35, 37, 40);
}

.footer a {
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}
