.mainSection {
  flex: 1;
}

.topBar {
  padding-top: 2rem;
}

.topBar .topRight {
  text-align: right;
}

.topBar .topMobile {
  margin-top: 1rem;
  text-align: center;
}

.topBar .inner {
  display: inline-block;
}

.topBar a {
  color: rgb(0, 169, 165);
}

.topRight a {
  margin-right: 2rem;
}

.body {
  text-align: center;
  margin-top: 10rem;
  font-size: 20px;
}

.body a {
  color: rgb(0, 169, 165);
}

.body a:hover {
  text-decoration: underline;
}
